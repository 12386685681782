import React from 'react'
import Gql from '../assets/icons/gql.svg'
import Js from '../assets/icons/js.svg'
import Logstash from '../assets/icons/logstash.svg'
import Mongo from '../assets/icons/mongo.svg'
import Node from '../assets/icons/node.svg'
import ReactSvg from '../assets/icons/reactjs.svg'
import Ts from '../assets/icons/ts.svg'
import Wp from '../assets/icons/wp.svg'
import Docker from '../assets/icons/docker.svg'
import Elastic from '../assets/icons/elastic.svg'
import Apollo from '../assets/icons/apollo.svg'
import Aws from '../assets/icons/aws.svg'
import Linux from '../assets/icons/linux.svg'
import MySQL from '../assets/icons/mysql.svg'
import Twilio from '../assets/icons/twilio.svg'
import Filebeat from '../assets/icons/beats.svg'
import Redis from '../assets/icons/redis.svg'

const techIcon = ({ icon, style }) => {
  const icons = {
    GraphQL: () => <Gql style={style}></Gql>,
    JavaScript: () => <Js style={style}></Js>,
    Logstash: () => <Logstash style={style}></Logstash>,
    MongoDB: () => <Mongo style={style}></Mongo>,
    NodeJS: () => <Node style={style}></Node>,
    TypeScript: () => <Ts style={style}></Ts>,
    ReactJS: () => <ReactSvg style={style}></ReactSvg>,
    ReactNative: () => <ReactSvg style={style}></ReactSvg>,
    Wordpress: () => <Wp style={style}></Wp>,
    Docker: () => <Docker style={style}></Docker>,
    Elasticsearch: () => <Elastic style={style}></Elastic>,
    Apollo: () => <Apollo style={style}></Apollo>,
    AWS: () => <Aws style={style}></Aws>,
    Linux: () => <Linux style={style} />,
    MySQL: () => <MySQL style={style} />,
    Twilio: () => <Twilio style={style} />,
    Filebeat: () => <Filebeat style={style} />,
    Redis: () => <Redis style={style} />,
  }
  const Icon = icons[icon];
  return <Icon></Icon>;
}

techIcon.defaultProps = {
  style: { width: 50 }
}

export default techIcon
