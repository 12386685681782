import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import Section from '../components/sectionContainer'
import One from '../assets/icons/one.svg'
import Two from '../assets/icons/two.svg'
import Three from '../assets/icons/three.svg'
import Four from '../assets/icons/four.svg'
import Img from "gatsby-image"
import { PrimaryLink } from '../components/button'
import Icon from '../components/techIcon'
import { H1, H2, H3, H4 } from '../components/headings'

export default function Template({
  data, pageContext: { nextPath }
}) {
  const {
    caseStudiesJson: {
      description,
      path,
      title,
      overview,
      objectives,
      challenges,
      technicalOverview,
      outcome,
      client,
      industry,
      location,
      tech,
      coverStyle,
    },
    coverImage,
    screensImage,
  } = data;

  let screensStyle = { objectFit: 'contain' };
  if (path === '/babele') screensStyle.top = 120;

  return (
    <Layout>
      <SEO title={`Appes - ${title}`} />
      <Header />
      <CSContainer>
        <CoverSection id={`cover-${path}`} style={{ backgroundColor: "#F2F2F2"}}>
          <CoverContainer>
            <CoverTriangle />
            <CoverLeft>
              <Title>{title}</Title>
              <div style={{ width: coverStyle === 'portrait' ? '58%' : '85%' }}>
                <Img imgStyle={{ objectFit: 'contain' }} alt="Cover project image" fluid={coverImage.childImageSharp.fluid} />
              </div>
            </CoverLeft>
            <CoverRight>
              <H2 style={{ maxWidth: 500 }}>{description}</H2>
            </CoverRight>
          </CoverContainer>
        </CoverSection>
        <Section id={`overview-${path}`} >
          <OverviewContainer>
            <HeadingOdd>
              <One style={{ height: 140, width: 180 }}/>
              <H2>Overview</H2>
            </HeadingOdd>
            <DownSection style={{ justifyContent: 'space-between' }}>
              <Left>
                {overview.map(paragraph => <p>{paragraph}</p>)}
                <H3 style={{ color: "#E23C30" }}>Objectives</H3>
                <ol>{ objectives && objectives.map(o => <li>{ o }</li> ) }</ol>
              </Left>
              <Right>
                <Row>
                  <Column>
                    <H4>Client</H4>
                    <p>{client}</p>
                  </Column>
                  <Column>
                    <H4>Industry</H4>
                    <p>{industry}</p>
                  </Column>
                  <Column>
                    <H4>Location</H4>
                    <p>{location}</p>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <H4>Tech Stack</H4>
                    <IconsContainer>
                      {
                        tech && tech.map( t => <div style={{ marginRight: 5, marginBottom: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Icon icon={t} style={{ width: 30, height: 30 }} />
                          <p style={{ marginTop: 5, marginBottom: 0, fontSize: 15, width: 85, textAlign: "center" }}>{t}</p>
                        </div>)
                      }
                    </IconsContainer>
                  </Column>
                </Row>
              </Right>
            </DownSection>
          </OverviewContainer>
        </Section>
        <Section id={`challenges-${path}`} style={{ backgroundColor: "#F2F2F2" }}>
          <ChallengesContainer>
            <HeadingEven>
              <H2>Project challenges</H2>
              <Two style={{ height: 140, width: 180 }}/>
            </HeadingEven>
            <ChallengesDownSection>
              { challenges && challenges.map(c => <p>{c}</p>) }
            </ChallengesDownSection>
          </ChallengesContainer>
        </Section>
        <Section style={{ paddingTop: 100 }}>
          <ScreenContainer>
            <Img
              style={{ width: "70%", zIndex: 1 }}
              imgStyle={screensStyle}
              fluid={screensImage.childImageSharp.fluid}
            />
            <Trapezoid />
          </ScreenContainer>
        </Section>
        <Section id={`technical-${path}`}>
          <TechnicalContainer>
            <HeadingOdd>
              <Three id="three" style={{ height: 140, width: 186 }}/>
              <H2>Technical Overview</H2>
            </HeadingOdd>
            <ChallengesDownSection style={{ minHeight: 700 }}>
              {
                technicalOverview && technicalOverview.map(t => <p>{t}</p>)
              }
            </ChallengesDownSection>
          </TechnicalContainer>
        </Section>
        <Section id={`Outcome-${path}`} style={{ minHeight: 590, backgroundColor: "#E23C30" }}>
          <OutcomeContainer>
            <HeadingEven>
              <H2 style={{ color: '#FFF' }}>Outcome</H2>
              <Four style={{ height: 140, width: 180 }}/>
            </HeadingEven>
            <OutcomeDownSection style={{ flexDirection: 'column' }}>
              {
                outcome && outcome.map(t => <p style={{ color: "#FFF", maxWidth: 800, textAlign: 'center' }}>{t}</p>)
              }
              <ButtonsContainer>
                <PrimaryLink
                  label={ nextPath === '/#header' ? '← Home' : 'Next project →' }
                  to={nextPath}
                  width={ nextPath === '/#header' ? 148 : 202 }
                  color="#FFFFFF"
                  background="#E23C30"
                  bordercolor="white"/>
                <PrimaryLink
                  label='Have an idea? →'
                  to="/#contact"
                  color="#E23C30"
                  background="white"
                  bordercolor="white"/>
              </ButtonsContainer>
            </OutcomeDownSection>
          </OutcomeContainer>
        </Section>
      </CSContainer>
      <Footer />
    </Layout>
  )
}

const CSContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 167px 0;
  height: 100%;

  @media (max-width: 767px) {
    padding: 0 0 50px 0;
  }
`

const CoverSection = styled(Section)`
  min-height: 530px;

  @media (max-width: 1336px) {
    min-height: 430px;
  }

  @media (max-width: 767px) and (orientation: landscape) {
    min-height: 300px;
  }

  @media (max-width: 767px) and (orientation: portrait) {
    min-height: 600px;
  }
`

const CoverContainer = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: row;

  @media (max-width: 767px) and (orientation: portrait) {
    flex-direction: column-reverse;
  }
`

const CoverLeft = styled.div`
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px 0 0 60px;

  @media (max-width: 767px) and (orientation: portrait) {
    padding: 0;
    align-items: center;
  }
`

const CoverRight = styled.div`
  height: 100%;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 80px;

  @media (max-width: 767px) {
    padding: 30px 30px 0 30px;

    h2 {
      text-align: center;
    }
  }
`

const CoverTriangle = styled.div`
  width: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 691px 432px 0;
  border-color: transparent transparent #E23C30 transparent;

  @media (max-width: 1336px) {
    border-width: 0 590px 230px 0;
  }

  @media (max-width: 767px) {
    border-width: 0 290px 180px 0;
  }
`

const Title = styled(H1)`
  font: Bold  21px/24px Vremena Grotesk;
  letter-spacing: -0.52px;
  color: #000000;
  text-align: left;
  margin-left: 100px;

  @media (max-width: 1336px) {
    font: Bold  21px/24px Vremena Grotesk;
    margin-left: 0;
  }
`

const HeadingOdd = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-bottom: 1px solid #B3B3B3;
  padding: 0 100px 61px 100px;

  h2 {
    margin: 0 0 0 30px;
  }

  @media (max-width: 1336px) {
    padding: 0 50px 30px 50px;

    svg {
      width: 128px !important;
      height: 100px !important;
    }
  }

  @media (max-width: 767px) {
    padding: 0 10px 20px 10px;

    svg {
      width: 102px !important;
      height: 80px !important;
    }
  }
`

const HeadingEven = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #B3B3B3;
  padding: 0 100px 61px 100px;

  h2 {
    margin: 0;
  }

  @media (max-width: 1336px) {
    padding: 0 50px 30px 50px;

    svg {
      width: 128px !important;
      height: 100px !important;
    }
  }

  @media (max-width: 767px) {
    padding: 0 10px 20px 10px;
  }
`

const OutcomeDownSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 100px 0 100px;
  width: 100%;

  p {
    text-align: left;
    font: Regular 22px/26px Noah;
    font-size: 23px;
    letter-spacing: -0.55px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 1336px) {
    padding: 25px 50px 0 50px;
  }

  @media (max-width: 767px) {
    padding: 25px 10px 0 10px;
  }
`

const DownSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 50px 0 50px;
  width: 100%;

  ol {
    text-align: left;
    font-size: 22px;
    font-weight: 500;
    font-family: "Noah";
    line-height: 26px;
    letter-spacing: -0.55px;
    color: #000000;
    opacity: 1;
    margin-top: 10px;
  }

  li {
    margin: 0;
  }

  p {
    text-align: left;
    font: Regular 22px/26px Noah;
    font-size: 23px;
    letter-spacing: -0.55px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 1336px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    padding: 50px 20px 0 20px;
  }
`

const ChallengesDownSection = styled(DownSection)`
  flex-direction: column;
  flex-wrap: wrap;
  height: 400px;
  align-items: flex-start;
  padding: 50px 60px 0 60px;

  p {
    width: 450px;
  }

  @media (max-width: 1336px) {
    flex-wrap: no-wrap;
    height: inherit;
    padding: 50px 50px 0 50px;

    p {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    padding: 50px 10px 0 10px;

    p {
      width: 100%;
    }
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const IconsContainer = styled.div`
  display: flex;
  width: 450px;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1336px) {
    width: 100%;
  }
`;

const TechnicalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 70px 60px 0 60px;

  @media (max-width: 1336px) {
    padding: 30px 30px 0 30px;
  }
`;

const Trapezoid = styled.div`
  left: 0;
  bottom: 0;
  position: absolute;
  border-bottom: 150px solid #E23C30;
  border-left: 270px solid transparent;
  border-right: 270px solid transparent;
  height: 0;
  width: 100%;

  @media (max-width: 767px) {
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
  }
`

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 40px;
  align-items: center;
  height: 100%;
  position: relative;
`

const ChallengesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 65px 60px;

  @media (max-width: 1336px) {
    padding: 30px 30px;
  }
`

const Left = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 1336px) {
    width: 100%;
  }
`

const Right = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;

  h4 {
    color: #E23C30;
  }

  p {
    margin-top: 6px;
    text-align: left;
    font-size: 15px;
    font-weight: Regular;
    font-family: Noah;
    line-height: 18px;
    letter-spacing: -0.38px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 1336px) {
    width: 100%;
  }
`

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 60px 50px 60px;

  @media (max-width: 1336px) {
    padding: 50px 30px;
  }
`

const OutcomeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 60px;

  @media (max-width: 1336px) {
    padding: 30px 30px;
  }

`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 470px;

  @media (max-width: 767px) {
    flex-direction: column;

    a {
      margin: 10px 0;
      width: 100% !important;
    }
  }
`

export const pageQuery = graphql`
  query CaseStudyByPath($path: String!, $cover: String!, $screens: String!) {
    caseStudiesJson(path: { eq: $path }) {
      date(formatString: "MMMM DD, YYYY")
      path
      title
      description
      overview
      objectives
      challenges
      technicalOverview
      outcome
      client
      industry
      location
      tech
      coverStyle
    }
    coverImage: file(relativePath: { eq: $cover }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    screensImage: file(relativePath: { eq: $screens }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
